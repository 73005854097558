<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">Domains</router-link>
                </p>
                </v-col>
            </v-row>
        <v-row justify="center" class="py-5 mt-2">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <h1 class="text-h6 font-weight-light text-center">{{ this.$route.params.domain }}</h1>
            <!-- <h1 class="text-caption font-weight-light text-center">{{ domain.status }}</h1> -->
            </v-col>
        </v-row>
        <v-row justify="center" class="py-5 px-10">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">

                <v-card tile elevation="4" class="pa-0 mb-6" v-if="domain">
                    <v-toolbar short flat color="#55308D" dark>
                        <v-toolbar-title>Manage Domain</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!-- <v-menu offset-y left>
                            <template #activator="{ on }">
                                <v-btn icon v-on="on">
                                    <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                </v-btn>
                            </template>
                            <v-list class="ma-0 pa-0">
                                <v-list-item-group>
                                <v-list-item :to="{ name: 'account-view-domain', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">
                                    <v-list-item-title>Manage Domain</v-list-item-title>
                                </v-list-item>
                                </v-list-item-group>
                            </v-list>
                        </v-menu> -->
                    </v-toolbar>
                    <v-card-text>

                <p class="text-overline mb-0">Registrar</p>
                <p class="mb-0 pb-0">
                    <router-link :to="{ name: 'account-view-whois', params: { accountId: this.$route.params.accountId, domain: domain.name } }">View whois</router-link>
                </p>
                <template v-if="!whois">
                <p class="mb-0 pb-0">
                    Checking whois...
                </p>
                </template>
                <template v-if="whois">
                <p class="mb-0 pb-0">
                    Registrar:
                    <template v-if="registrarURL">
                        <a :href="registrarURL" target="_blank">{{ registrarName }}</a>
                    </template>
                    <template v-if="!registrarURL">
                        {{ registrarName }}
                    </template>
                    <template v-if="whois.text">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="whoisDialog = true" v-bind="attrs" v-on="on">
                                <font-awesome-icon :icon="['fas', 'info-circle']" class="blue--text"/>
                            </v-btn>
                        </template>
                        See full text
                    </v-tooltip>
                    <v-dialog v-model="whoisDialog">
                        <v-card elevation="4">
                            <v-toolbar short flat color="white">
                                <v-toolbar-title>Whois result for {{ domain.name }}</v-toolbar-title>
                            </v-toolbar>
                            <v-form class="pa-5" onSubmit="return false;">
                            <v-textarea :value="whois.text" rows="10" />
                            </v-form>
                        </v-card>
                    </v-dialog>
                    </template>
                </p>
                </template>
                <p class="mb-0 pb-0">
                    <!-- TODO: check if domain is registered; show registrar information here -->
                    <!-- TODO: check whois information for this domain, is it what the admin configured here that it should be? we can alert admin that whois information is out of date or doesn't match -->
                    <!-- <template v-if="domain.visible"><span class="font-weight-bold">Public</span> (browsable)</template>
                    <template v-if="!domain.visible"><span class="font-weight-bold">Private</span> (by invitation only, members only, access control, etc.)</template> -->
                    <!-- <v-btn class="blue--text no-print" @click="dialogEditDisplayName = true" icon>
                        <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                    </v-btn> -->
                </p>

                <p class="text-overline mb-0 mt-8">Domain status</p>
                <template v-if="domain.status === 'new'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                    Not verified
                </p>
                <p class="mb-0 pb-0 text-caption">
                    You must prove ownership of the domain before it can be used with LibertyDNS.
                </p>
                <p class="mb-0 pb-0">
                    <router-link :to="{ name: 'account-edit-domain-verify', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">Verify domain ownership</router-link>
                </p>
                </template>
                <template v-if="domain.status === 'pending'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                    Verification in progress
                    <!-- TODO: show the verification code here that the user must set in registrar's dns; also maybe a dialog with extra help, or link to public help page -->
                </p>
                <p class="mb-0 pb-0 text-caption">
                    You must create a DNS record with this code to prove ownership of the domain.
                </p>
                </template>
                <template v-if="domain.status === 'verified'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'check']" class="green--text"/>
                    Verified
                </p>
                <!-- <p class="mb-0 pb-0 text-caption">
                    You must create a DNS record with this code to prove ownership of the domain.
                </p> -->
                </template>

                <p class="text-overline mb-0 mt-8">Nameservers</p>
                <template v-if="status === 'ready'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'check']" class="green--text"/>
                    DNS hosted by LibertyDNS.
                </p>
                </template>
                <template v-if="status === 'other'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                    DNS hosted elsewhere.
                </p>
                <p class="mb-0 pb-0 text-caption">
                    You can edit DNS records, but they will only be visible to queries directed at our servers specifically.
                </p>
                </template>
                <template v-if="status === 'pending'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                    DNS changes pending.
                </p>
                <p class="mb-0 pb-0 text-caption">
                    Looks like nameservers are being changed, check again later for updates. You can edit DNS records, but results may be unreliable until the change is complete.
                </p>
                </template>
                <p class="mb-0 pb-0" v-if="!Array.isArray(nameserverList)">
                    Checking nameservers...
                </p>
                <p class="mb-0 pb-0" v-if="Array.isArray(nameserverList) && nameserverList.length === 0 && status !== 'error'">
                    No nameservers set for this domain.
                </p>
                <p class="mb-0 pb-0" v-if="Array.isArray(nameserverList) && nameserverList.length > 0">
                    Nameservers:
                    <v-chip label small v-for="(item, idx) in nameserverList" :key="idx" class="ml-1">
                        {{ item }}
                    </v-chip>
                </p>
                <template v-if="registrarDnsNameservers.length > 0 && status === 'error'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                    DNS configuration error.
                </p>
                <p class="mb-0 pb-0 text-caption">
                    The nameservers configured at the registrar are not currently serving DNS information for this domain.
                </p>
                <p class="mb-0 pb-0">
                    Nameservers:
                    <v-chip label small v-for="(item, idx) in registrarDnsNameservers" :key="idx" class="ml-1">
                        {{ item }}
                    </v-chip>
                </p>
                </template>
                <template v-if="registrarDnsNameservers.length === 0 && status === 'error'">
                <p class="mb-0 pb-0">
                    <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="amber--text"/>
                    Failed to look up nameservers.
                </p>
                <p class="mb-0 pb-0 text-caption">
                    Try again later, or check the settings at the domain registrar.
                </p>
                </template>
                <template v-if="Array.isArray(nameserverList) && status !== 'ready'">
                <p class="mb-0 pb-0">
                    <router-link :to="{ name: 'account-edit-domain-nameservers', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">Change nameservers</router-link>
                </p>
                </template>
                <!-- TODO: flag if dns info will be public (available to any internet query) or private (available only within the organization's vpn or to the organization's dns-over-http users where we can authenticate the client) -->
                <!--
                <p class="mb-0 pb-0">
                    <template v-if="domain.visible"><span class="font-weight-bold">Public</span> (browsable)</template>
                    <template v-if="!domain.visible"><span class="font-weight-bold">Private</span> (by invitation only, members only, access control, etc.)</template>
                    <v-btn class="blue--text no-print" @click="dialogEditDisplayName = true" icon>
                        <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                    </v-btn>
                </p>
                -->
                <p class="text-overline mb-0 mt-8">DNS</p>
                <p class="mb-0 pb-0">
                    <router-link :to="{ name: 'account-edit-dns', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">Edit DNS records</router-link>
                </p>

                <p class="text-overline mb-0 mt-8">Sites</p>
                <p class="mb-0 pb-0">
                    <router-link :to="{ name: 'account-view-domain-website-list', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">See all sites</router-link>
                </p>

                <!-- TODO: realm list -->
                <!-- <DomainViewList :list="list"/> -->
                <!-- <RealmList @selected="onSelectRealm" create class="mt-12"/> -->
                <p class="text-overline mb-0 mt-8">Other</p>
                <p class="mb-0 pb-0">
                    <!-- TODO: archive/unarchive the domain, so it doesn't even show up for admin unless they ask to look at archived domains -->
                    <!-- <v-btn color="white" class="red--text">Delete Domain</v-btn> -->
                    <router-link :to="{ name: 'account-delete-domain', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">How to delete this domain</router-link>
                </p>
                <!-- <p class="mb-0 pb-0">
                    <router-link :to="{ name: 'account-transfer-domain', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">How to transfer this domain to another account</router-link>
                </p> -->

                <template v-if="isPermitServiceAdmin">
                <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="purple--text"/></p>
                <p class="mb-0 pb-0">
                    <!-- <router-link :to="{ name: 'service-admin' }">Manage LibertyDNS</router-link> -->
                    <!-- Edit domain status -->
                    <v-btn text color="purple" class="px-0" @click="editDomainStatus('verified')" v-if="domain.status !== 'verified'">Verify domain</v-btn>
                    <v-btn text color="purple" class="px-0" @click="editDomainStatus('pending')" v-if="domain.status === 'verified'">Suspend domain</v-btn>
                </p>
                </template>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
// import RealmList from '@/components/domain-dashboard/RealmList.vue';

export default {
    components: {
        // RealmList,
    },
    data: () => ({
        account: null,
        domain: null,
        error: null,
        whois: null,
        whoisDialog: false,
        nameserverList: null,
        status: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
            user: (state) => state.user,
        }),
        accountName() {
            return this.isViewReady ? this.account?.name ?? 'Unknown' : '';
        },
        registrarName() {
            return this.whois?.info?.Registrar?.join(' / ') ?? 'Unknown';
        },
        registrarURL() {
            try {
                const list = this.whois?.info?.['Registrar URL'];
                if (Array.isArray(list) && list.length > 0) {
                    const inputURL = new URL(list[0]);
                    return inputURL.toString();
                }
                return null;
            } catch (err) {
                return null;
            }
        },
        registrarDnsNameservers() {
            return this.whois?.info?.['Name Server'] ?? [];
        },
        // TODO: move this getter to vuex so we don't have to redefine it everywere... we'll just use mapGetters
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        isViewReady() {
            return this.account !== null && this.domain !== null;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadDomain() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadDomain: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.get({ name: this.$route.params.domain });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.domain = response;
                } else {
                    // TODO: redirect back to domain list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load domain', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadDomain: false });
            }
        },
        async checkWhois() {
            try {
                this.whois = null;
                this.$store.commit('loading', { checkWhois: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.checkWhois({ domain: this.$route.params.domain });
                console.log(`checkWhois response ${JSON.stringify(response)}`);
                if (response) {
                    this.whois = response;
                }
            } catch (err) {
                console.error('failed to check whois', err);
            } finally {
                this.$store.commit('loading', { checkWhois: false });
            }
        },
        async checkNameservers() {
            try {
                this.nameserverList = null;
                this.$store.commit('loading', { checkNameservers: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.checkNameservers({ domain: this.$route.params.domain });
                console.log(`checkNameservers response ${JSON.stringify(response)}`);
                if (Array.isArray(response?.list)) {
                    this.nameserverList = response.list;
                }
                if (typeof response.status === 'string') {
                    this.status = response.status;
                }
            } catch (err) {
                console.error('failed to check nameservers', err);
            } finally {
                this.$store.commit('loading', { checkNameservers: false });
            }
        },
        async editDomainStatus(newValue) {
            // NOTE: server does access control check for this, only service admin may use the function
            try {
                this.$store.commit('loading', { editDomainStatus: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.edit({ name: this.$route.params.domain }, { status: newValue });
                console.log(`domain/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response?.isEdited) {
                    this.domain.status = newValue;
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Domain status updated', message: `New status: ${newValue}` });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update domain status' });
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to update domain status' });
            } finally {
                this.$store.commit('loading', { editDomainStatus: false });
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadDomain();
        this.checkWhois();
        this.checkNameservers();
    },
};
</script>
